// TODO(Fred): Had to change type to any to avoid ESLINT errors in general-info.container.html.
//   Work with BE to fix these types (twitter, linkedin and facebook). Should be consistent and always be the same type.
//   Today they can be of type: string | AccredibleSocialMedium
export interface AccredibleSocialMedia {
  twitter?: any;
  linkedin?: any;
  facebook?: any;
  instagram?: any;
  tiktok?: any;
  youtube?: any;
  linkedin_id?: string;
  seo?: SEO;
}

export interface AccredibleSocialMedium {
  id?: string;
  url?: string;
  message?: string;
}

interface SEO {
  title: string;
  description: string;
}
