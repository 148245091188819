import { Endorsement } from './endorsement.model';
import { AccredibleEvidenceItem } from './evidence.model';
import { AccredibleAttributeTypes, AccredibleGroup, AccredibleJobInsights } from './group.model';
import { AccredibleCredentialIssuer, AccredibleIssuer } from './issuer.model';

export interface AccredibleCredentialNew {
  id: number;
  uuid: string;
  name: string;
  created_at: Date;
  license_id: string;
  encoded_id: string;
  expired: boolean;
  expired_on: Date;
  issued_on: Date;
  published_on: Date;
  bounced_email: boolean;
  bounce_reason: string;
  is_sample: boolean;
  approve: boolean;
  private: boolean;
  linkedin_engaged: boolean;
  recipient_email: string;
  recipient_name: string;
  recipient_name_change_check: boolean; // TODO: Clarify this one with Bo
  recipient_name_change_request_time: Date;
  recipient_name_change_requested: string;
  recipient_engaged: boolean;
  recipient_opened: boolean;
  campaign_emails?: CredentialCampaignEmail[];

  // Foreign keys group_id and issuer_id
  group?: AccredibleGroup;
  issuer?: AccredibleIssuer;
}

export interface CredentialCampaignEmail {
  id: number;
  campaign_id: number;
  bounced: boolean;
  bounced_at: string;
}

/**
 * @deprecated This interface is deprecated and should not be used in new features.
 */
interface AccredibleBaseCredential {
  id: number;
  uuid: string;
  name: string;
  description: string;
  course_name: string;
  url: string;
  username: string;

  // Local helper variables
  isCustomCredential: boolean;
}

export interface Endorser {
  id: number;
  name: string;
  description: string;
}

export interface EndorsementDetails extends Endorsement {
  endorser: Endorser;
}
/**
 * @deprecated This interface is deprecated and should not be used anymore. Use {@link AccredibleCredentialNew} instead.
 * Any new endpoints returning credentials should use the {@link AccredibleCredentialNew} interface.
 *
 * The main goal for this change is for BE to start being consistent in the field names sent on responses,
 * if needed communicate with BE in order for them to be consistent with the {@link AccredibleCredentialNew} interface.
 */
export interface AccredibleCredential extends AccredibleBaseCredential {
  issued_on: string;
  expired_on: string;
  group_id: number;
  language: string;
  approve: boolean;
  private_key: string;
  private: boolean;
  sample: boolean; // is demo credential
  expired: boolean;
  grade: string;
  published_on: string;
  encoded_id: string;
  license_id: string;
  complete: boolean;
  learning_outcomes: string[];
  achievement_id: string;
  allow_supplemental_references: boolean;
  allow_supplemental_evidence: boolean;
  course_link: string;
  has_user: boolean;
  verified_user: boolean;
  email_embed_image: string;
  opengraph_image: string;
  badge_assertion_url: string;
  embed_image: string;
  own_credential: boolean;
  badge_image: string;
  custom: { [key: string]: string };
  custom_attributes: { [key: string]: string };
  custom_type: { [key: string]: AccredibleAttributeTypes };
  block_credential: boolean;
  block_credential_address: string;
  blockcerts: boolean;
  verified: boolean;
  is_confirmed: boolean;
  is_pending_confirmation: boolean;
  website_email_embed_badge_image: string;
  website_email_embed_certificate_image: string;
  twittercard_image: string;
  user: { profile: string };
  recipient: Recipient;
  evidence_items: AccredibleEvidenceItem[];
  references: AccredibleCredentialReference[];
  issuer: AccredibleCredentialIssuer;
  group: AccredibleGroup;
  associated_collections: AssociatedCollection[];
  job_insights: AccredibleJobInsights;
  eligible_spotlight_directories: EligibleSpotlightDirectory[];
  physical_awards_last_order_id?: string;
  endorsements?: EndorsementDetails[];

  [key: string]: any;
}

export interface EligibleSpotlightDirectory {
  id: number;
  name: string;
  domain: string;
  published: boolean;
  link?: string;
  completion_percent?: number;
  visible?: boolean;
}

export interface AccredibleCustomCredential extends AccredibleBaseCredential {
  expiry_date: string;
  issue_date: string;
  course_url: string;
  issuer_name: string;
  recipient_name: string;
  sync_id: string;
}

interface AssociatedCollection {
  id: string;
  name: string;
}

export interface Recipient {
  email: string;
  name: string;
  // TODO: API should return a number
  id: number;
}

export interface AccredibleCredentialReference {
  id: number;
  description: string;
  relationship: string;
  supplemental: boolean;
  approve: boolean;
  referee: Referee;
}

interface Referee {
  id: number;
  name: string;
  title: string;
  url: string;
  avatar_image_url: string;
}
