import { AccredibleLanguage } from '@accredible-frontend-v2/models';
import localeAr from '@angular/common/locales/ar';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeEs419 from '@angular/common/locales/es-419';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeHe from '@angular/common/locales/he';
import localeHi from '@angular/common/locales/hi';
import localeHr from '@angular/common/locales/hr';
import localeHu from '@angular/common/locales/hu';
import localeId from '@angular/common/locales/id';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeMs from '@angular/common/locales/ms';
import localeNb from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeSl from '@angular/common/locales/sl';
import localeSv from '@angular/common/locales/sv';
import localeTh from '@angular/common/locales/th';
import localeTr from '@angular/common/locales/tr';
import localeVi from '@angular/common/locales/vi';
import localeZh from '@angular/common/locales/zh';

export const AVAILABLE_LANGUAGES: AccredibleLanguage[] = [
  {
    code: 'ar',
    name: 'عربى',
    matLib: localeAr,
  },
  {
    code: 'cs',
    name: 'Česky',
    matLib: localeCs,
  },
  {
    code: 'da',
    name: 'Dansk',
    matLib: localeDa,
  },
  {
    code: 'de',
    name: 'Deutsch',
    matLib: localeDe,
  },
  {
    code: 'el',
    name: 'Νέα Ελληνικά',
    matLib: localeEl,
  },
  {
    code: 'en',
    name: 'English (US)',
    matLib: localeEn,
  },
  {
    code: 'en-gb',
    name: 'English (UK)',
    matLib: localeEnGB,
  },
  {
    code: 'es',
    name: 'Español',
    matLib: localeEs,
  },
  {
    code: 'es-419',
    name: 'Español (Latinoamérica)',
    matLib: localeEs419,
  },
  {
    code: 'fi',
    name: 'Suomi',
    matLib: localeFi,
  },
  {
    code: 'fr',
    name: 'Français',
    matLib: localeFr,
  },
  {
    code: 'he',
    name: 'עברית',
    matLib: localeHe,
  },
  {
    code: 'hi',
    name: 'हिन्दी',
    matLib: localeHi,
  },
  {
    code: 'hr',
    name: 'Hrvatski',
    matLib: localeHr,
  },
  {
    code: 'hu',
    name: 'Magyar',
    matLib: localeHu,
  },
  {
    code: 'id',
    name: 'Bahasa Indonesia',
    matLib: localeId,
  },
  {
    code: 'it',
    name: 'Italiano',
    matLib: localeIt,
  },
  {
    code: 'ja',
    name: '日本語',
    matLib: localeJa,
  },
  {
    code: 'ko',
    name: '한국어',
    matLib: localeKo,
  },
  {
    code: 'ms',
    name: 'Bahasa Melayu',
    matLib: localeMs,
  },
  {
    code: 'nb',
    name: 'Norsk Bokmål',
    matLib: localeNb,
  },
  {
    code: 'nl',
    name: 'Nederlands',
    matLib: localeNl,
  },
  {
    code: 'pl',
    name: 'Polski',
    matLib: localePl,
  },
  {
    code: 'pt-br',
    name: 'Português (Brasil)',
    matLib: localePt,
  },
  {
    code: 'pt-pt',
    name: 'Português (Portugal)',
    matLib: localePt,
  },
  {
    code: 'ro',
    name: 'Română',
    matLib: localeRo,
  },
  {
    code: 'ru',
    name: 'Русский',
    matLib: localeRu,
  },
  {
    code: 'sk',
    name: 'Slovenský',
    matLib: localeSk,
  },
  {
    code: 'sl',
    name: 'Slovenščina',
    matLib: localeSl,
  },
  {
    code: 'sv',
    name: 'Svenska',
    matLib: localeSv,
  },
  {
    code: 'th',
    name: 'ไทย',
    matLib: localeTh,
  },
  {
    code: 'tr',
    name: 'Türk',
    matLib: localeTr,
  },
  {
    code: 'vi',
    name: 'Việtnam',
    matLib: localeVi,
  },
  {
    code: 'zh-tw',
    name: '繁體中文',
    matLib: localeZh,
  },
  {
    code: 'zh-cn',
    name: '简体中文',
    matLib: localeZh,
  },
];

export const getAvailableLanguages = (themeLanguageCodes?: string[]): AccredibleLanguage[] => {
  // Pick theme languages from available languages or use all available languages if none are specified by the theme
  return themeLanguageCodes
    ? AVAILABLE_LANGUAGES.filter((lang) => {
        return themeLanguageCodes.includes(lang.code);
      })
    : AVAILABLE_LANGUAGES;
};
