import { environment } from '@accredible-frontend-v2/envs';
import { ThemeJSONFile } from '@accredible-frontend-v2/models';
import { AccountsRedirectionKey } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAuthenticationApiService } from '@accredible-frontend-v2/services/authentication';
import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleCookiesService } from '@accredible-frontend-v2/services/cookies';
import {
  AccredibleLanguageService,
  getAvailableLanguages,
  registerSupportedLocalesData,
} from '@accredible-frontend-v2/services/language';
import { AccredibleSeoService } from '@accredible-frontend-v2/services/seo';
import { ThemeGeneratorService } from '@accredible-frontend-v2/services/theme-generator';
import { addGoogleTagManager } from '@accredible-frontend-v2/utils/google-tag-manager-helper';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ThemeHelper } from '../themes/theme.helper';

@Injectable()
export class SpotlightLoadService {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _authenticationApi: AccredibleAuthenticationApiService,
    private readonly _http: HttpClient,
    private readonly _cookies: AccredibleCookiesService,
    private readonly _language: AccredibleLanguageService,
    private readonly _browserStorage: AccredibleBrowserStorageService,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _matIconRegistry: MatIconRegistry,
    private readonly _themeGenerator: ThemeGeneratorService,
    private readonly _seo: AccredibleSeoService,
  ) {}

  initializeApp(): Promise<boolean> {
    // Pass the themeHelper into the seo service to be used to get the theme metadata
    this._seo.setTheme(ThemeHelper);

    return new Promise<boolean>((resolve) => {
      this._themeGenerator
        .setTheme(ThemeHelper)
        .then((themeName: string) => {
          this._setLanguages(themeName);
          resolve(true);
        })
        .catch(() => {
          console.error('Theme styles failed to load');
          resolve(false);
        });

      // We can do pre-app initialization here
      this._registerAccredibleSvgIcons();
      addGoogleTagManager();

      // FIXME: We will do something similar for Spotlight
      // Load domain whitelabel settings if they exist, else it will default to credential.net
      // this._credentialsStore.dispatch(CredentialsActions.loadOrganizationSettings());
    });
  }

  loadThemeConfig(themeId: string): Observable<ThemeJSONFile> {
    return this._http.get<ThemeJSONFile>(`${environment.themingS3BaseUrl}/configs/${themeId}.json`);
  }

  // TODO(Matt): Refactor this method into a shared library
  validateSessionToken(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Check if token is in URL query params
      const urlParams = new URLSearchParams(this._document.location.search);
      if (urlParams.has('token') || urlParams.has('jwt')) {
        if (this._document.location.pathname === '/auth') {
          // this._loginWithOneTimeToken(urlParams.get('token')).then((isLoggedIn) =>
          //   resolve(isLoggedIn),
          // );
          // } else if (this._document.location.pathname === '/user/jwt') {
          // this._loginWithSSOToken(urlParams.get('jwt')).then((isLoggedIn) => resolve(isLoggedIn));
          // } else if (isUuidSlashEditRoute(this._document.location.pathname)) {
          // const credentialIdOrUuid = this._document.location.pathname.split('/')[1];
          // this._loginWithSSOToken(
          //   urlParams.get('jwt'),
          //   +urlParams.get('provider'),
          //   credentialIdOrUuid,
          // ).then((isLoggedIn) => resolve(isLoggedIn));
        } else {
          // User is logged in if token has not expired
          this._cookies.set(AccountsRedirectionKey.SESSION_TOKEN_COOKIE, urlParams.get('token'));
          this._checkSession().then((isLoggedIn) => resolve(isLoggedIn));
        }

        // Remove token from URL query params
        WindowHelper.removeQueryParamFromUrl('token', urlParams);
      } else if (this._cookies.check(AccountsRedirectionKey.SESSION_TOKEN_COOKIE)) {
        // If session token exists, user is logged in
        this._checkSession().then((isLoggedIn) => resolve(isLoggedIn));
      } else {
        // User is not logged in
        resolve(false);
      }
    });
  }

  private _checkSession(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Test if token has not expired
      this._authenticationApi.checkSession().subscribe({
        // Success: User is logged in
        next: () => resolve(true),
        // Error (401): The session token has expired or the user logged out in other app
        // Our ApiService will delete our session_token from cookies and redirect the user to the login page
        error: () => resolve(false),
      });
    });
  }

  private _setLanguages(themeName: string): void {
    // Register supported languages
    let supportedLanguages;
    // If previewing a theme we only support en language
    if (environment.theming) {
      supportedLanguages = getAvailableLanguages(['en']);
    } else {
      supportedLanguages = getAvailableLanguages(
        accredibleCustomThemesMetadata[themeName].languages,
      );
    }

    registerSupportedLocalesData(supportedLanguages);

    // Set active language
    const urlParams = new URLSearchParams(this._document.location.search);
    const languageCode = urlParams.get(AccountsRedirectionKey.LANGUAGE);
    this._language.setLanguage(languageCode, themeName);
    if (languageCode) {
      this._browserStorage.set(AccountsRedirectionKey.LANGUAGE, languageCode);
    }
    // Once set we can remove the language from the url query params
    WindowHelper.removeQueryParamFromUrl(AccountsRedirectionKey.LANGUAGE, urlParams);
  }

  private _registerAccredibleSvgIcons(): void {
    this._matIconRegistry.addSvgIcon(
      'user',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/user.svg'),
    );
  }
}
