/**
 * Gainsight PX SDK docs can be found here https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK
 */

declare let aptrinsic: any;

export interface GainsightIdentifyPayload {
  user: {
    // User Fields
    id: string | number; // Required for logged in app users
    email: string;
    firstName: string;
  };
  account?: {
    // Account Fields
    id: string | number; // Required
    name?: string;
  };
}

export const addGainsightPXCode = (gainsightTagKey: string): void => {
  if (!gainsightTagKey) {
    return;
  }

  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.async = true;
  scriptTag.innerHTML = `(function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
      (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
      var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
      var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
      })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js","${gainsightTagKey}",{"iframeModeEnabled":true});`;

  document.head.insertBefore(scriptTag, document.head.firstChild);
};

export const identifyGainsightUser = (
  gainsightTagKey: string,
  data: GainsightIdentifyPayload,
): void => {
  if (!gainsightTagKey) {
    return;
  }

  try {
    const { user, account } = data;
    aptrinsic('identify', user, account);
  } catch (e) {
    // aptrinsic is not defined
  }
};

export const gainsightPXCustomEvent = (eventName: string, data: any): void => {
  try {
    aptrinsic('track', eventName, data);
  } catch (e) {
    // aptrinsic is not defined
  }
};
